
.leaflet-popup {
  width: 250px;

  &-content {
    margin: 0;

    &-wrapper {
      border-radius: 5px;
      padding: 0;
      padding-bottom: 1px;
    }
  }

}

.leaflet-container a.leaflet-popup-close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 10px;
  border-radius: 50px;
  background-color: #FFFFFF;
  width: 25px;
  height: 25px;

  > span {
    color: #0181BA;
    font-size: 23px;
  }
}
.marker-popup {

  &__header {
    position: relative;

    .street_view {
      position: absolute;
      top: 10px;
      left: 10px;
      background-color: #FFFFFF;
      padding: 5px 11px;
      border-radius: 100px;
      font-size: 13px;
      font-weight: 600;

      .street_view_link {
        color: #0181BA;
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }

    .maximize_img_btn {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 10px;
      right: 10px;
      border-radius: 50px;
      background-color: #FFFFFF;
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
  }

  &__content {
    padding: 15px 20px;
    font-size: 13px;

    &__item {
      display: flex;
      gap: 5px;
      line-height: 22px;

      .title {
        color: #0181BA;
        font-weight: 600;
      }

      .value {
        color: #000000;
        font-weight: 300;
      }
    }

    &__availability{
      .free {
        font-size: 13px;
        color: #0181BA;
        font-weight: 600;
      }
      .busy {
        font-size: 13px;
        color: #B74311;
        font-weight: 600;
      }
    }

    &__buttons {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .border_button {
        width: 100%;
        padding: 6px;
        font-size: 13px;
        font-weight: 600;
        color: #0181BA;
        line-height: 22px;
        border-radius: 3px;
        border: 1px solid #0181BA;
        background: #FFFFFF;
      }


      .normal_button {
        width: 100%;
        padding: 6px;
        font-size: 13px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 22px;
        border-radius: 3px;
        background: linear-gradient(90.02deg, #0181BA 23.52%, #3BB7D2 100%);
      }
    }
  }


}

.image-popup-show {
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: 5px;
  padding: 2px;
  background-color: white;
  transform: translateX(-50%) translateY(-50%);
  overflow: hidden;
  max-width: calc(100vw - 60px);

  img {
    border-radius: 5px;
  }
//   width: 20vw;
//   height: 50vh;
//   z-index: 999;
}
